import { default as codesN0ZXh7pEc6Meta } from "/opt/buildhome/repo/pages/codes.vue?macro=true";
import { default as digicodeVeO2eTT2XwMeta } from "/opt/buildhome/repo/pages/digicode.vue?macro=true";
import { default as discussionpQYs9sRWFWMeta } from "/opt/buildhome/repo/pages/discussion.vue?macro=true";
import { default as drawL01BBCzlFAMeta } from "/opt/buildhome/repo/pages/draw.vue?macro=true";
import { default as felicitationmg5aokSHWeMeta } from "/opt/buildhome/repo/pages/felicitation.vue?macro=true";
import { default as forgot_45passwordcBWiw0xyOQMeta } from "/opt/buildhome/repo/pages/forgot-password.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as calendarIfaGzyaiUhMeta } from "/opt/buildhome/repo/pages/project/[id]/calendar.vue?macro=true";
import { default as indexE7yl81Z8I2Meta } from "/opt/buildhome/repo/pages/project/[id]/index.vue?macro=true";
import { default as createN2zhQFnaKeMeta } from "/opt/buildhome/repo/pages/project/[id]/task/create.vue?macro=true";
import { default as createQrlMsGiGHdMeta } from "/opt/buildhome/repo/pages/project/create.vue?macro=true";
import { default as indexjJXRPjWvhQMeta } from "/opt/buildhome/repo/pages/report/[id]/index.vue?macro=true";
import { default as creater0zV7U6xfOMeta } from "/opt/buildhome/repo/pages/report/create.vue?macro=true";
import { default as indexShpfogGWDEMeta } from "/opt/buildhome/repo/pages/report/index.vue?macro=true";
import { default as reset_45passwordR3tuU8TAkoMeta } from "/opt/buildhome/repo/pages/reset-password.vue?macro=true";
import { default as signup71EVNEoLpMMeta } from "/opt/buildhome/repo/pages/signup.vue?macro=true";
export default [
  {
    name: codesN0ZXh7pEc6Meta?.name ?? "codes",
    path: codesN0ZXh7pEc6Meta?.path ?? "/codes",
    meta: codesN0ZXh7pEc6Meta || {},
    alias: codesN0ZXh7pEc6Meta?.alias || [],
    redirect: codesN0ZXh7pEc6Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/codes.vue").then(m => m.default || m)
  },
  {
    name: digicodeVeO2eTT2XwMeta?.name ?? "digicode",
    path: digicodeVeO2eTT2XwMeta?.path ?? "/digicode",
    meta: digicodeVeO2eTT2XwMeta || {},
    alias: digicodeVeO2eTT2XwMeta?.alias || [],
    redirect: digicodeVeO2eTT2XwMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/digicode.vue").then(m => m.default || m)
  },
  {
    name: discussionpQYs9sRWFWMeta?.name ?? "discussion",
    path: discussionpQYs9sRWFWMeta?.path ?? "/discussion",
    meta: discussionpQYs9sRWFWMeta || {},
    alias: discussionpQYs9sRWFWMeta?.alias || [],
    redirect: discussionpQYs9sRWFWMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/discussion.vue").then(m => m.default || m)
  },
  {
    name: drawL01BBCzlFAMeta?.name ?? "draw",
    path: drawL01BBCzlFAMeta?.path ?? "/draw",
    meta: drawL01BBCzlFAMeta || {},
    alias: drawL01BBCzlFAMeta?.alias || [],
    redirect: drawL01BBCzlFAMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/draw.vue").then(m => m.default || m)
  },
  {
    name: felicitationmg5aokSHWeMeta?.name ?? "felicitation",
    path: felicitationmg5aokSHWeMeta?.path ?? "/felicitation",
    meta: felicitationmg5aokSHWeMeta || {},
    alias: felicitationmg5aokSHWeMeta?.alias || [],
    redirect: felicitationmg5aokSHWeMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/felicitation.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordcBWiw0xyOQMeta?.name ?? "forgot-password",
    path: forgot_45passwordcBWiw0xyOQMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordcBWiw0xyOQMeta || {},
    alias: forgot_45passwordcBWiw0xyOQMeta?.alias || [],
    redirect: forgot_45passwordcBWiw0xyOQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login4dfr6kRqBoMeta?.name ?? "login",
    path: login4dfr6kRqBoMeta?.path ?? "/login",
    meta: login4dfr6kRqBoMeta || {},
    alias: login4dfr6kRqBoMeta?.alias || [],
    redirect: login4dfr6kRqBoMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: calendarIfaGzyaiUhMeta?.name ?? "project-id-calendar",
    path: calendarIfaGzyaiUhMeta?.path ?? "/project/:id()/calendar",
    meta: calendarIfaGzyaiUhMeta || {},
    alias: calendarIfaGzyaiUhMeta?.alias || [],
    redirect: calendarIfaGzyaiUhMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/project/[id]/calendar.vue").then(m => m.default || m)
  },
  {
    name: indexE7yl81Z8I2Meta?.name ?? "project-id",
    path: indexE7yl81Z8I2Meta?.path ?? "/project/:id()",
    meta: indexE7yl81Z8I2Meta || {},
    alias: indexE7yl81Z8I2Meta?.alias || [],
    redirect: indexE7yl81Z8I2Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/project/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: createN2zhQFnaKeMeta?.name ?? "project-id-task-create",
    path: createN2zhQFnaKeMeta?.path ?? "/project/:id()/task/create",
    meta: createN2zhQFnaKeMeta || {},
    alias: createN2zhQFnaKeMeta?.alias || [],
    redirect: createN2zhQFnaKeMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/project/[id]/task/create.vue").then(m => m.default || m)
  },
  {
    name: createQrlMsGiGHdMeta?.name ?? "project-create",
    path: createQrlMsGiGHdMeta?.path ?? "/project/create",
    meta: createQrlMsGiGHdMeta || {},
    alias: createQrlMsGiGHdMeta?.alias || [],
    redirect: createQrlMsGiGHdMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/project/create.vue").then(m => m.default || m)
  },
  {
    name: indexjJXRPjWvhQMeta?.name ?? "report-id",
    path: indexjJXRPjWvhQMeta?.path ?? "/report/:id()",
    meta: indexjJXRPjWvhQMeta || {},
    alias: indexjJXRPjWvhQMeta?.alias || [],
    redirect: indexjJXRPjWvhQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/report/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: creater0zV7U6xfOMeta?.name ?? "report-create",
    path: creater0zV7U6xfOMeta?.path ?? "/report/create",
    meta: creater0zV7U6xfOMeta || {},
    alias: creater0zV7U6xfOMeta?.alias || [],
    redirect: creater0zV7U6xfOMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/report/create.vue").then(m => m.default || m)
  },
  {
    name: indexShpfogGWDEMeta?.name ?? "report",
    path: indexShpfogGWDEMeta?.path ?? "/report",
    meta: indexShpfogGWDEMeta || {},
    alias: indexShpfogGWDEMeta?.alias || [],
    redirect: indexShpfogGWDEMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/report/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordR3tuU8TAkoMeta?.name ?? "reset-password",
    path: reset_45passwordR3tuU8TAkoMeta?.path ?? "/reset-password",
    meta: reset_45passwordR3tuU8TAkoMeta || {},
    alias: reset_45passwordR3tuU8TAkoMeta?.alias || [],
    redirect: reset_45passwordR3tuU8TAkoMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: signup71EVNEoLpMMeta?.name ?? "signup",
    path: signup71EVNEoLpMMeta?.path ?? "/signup",
    meta: signup71EVNEoLpMMeta || {},
    alias: signup71EVNEoLpMMeta?.alias || [],
    redirect: signup71EVNEoLpMMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/signup.vue").then(m => m.default || m)
  }
]