import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tLmAP220nC from "/opt/buildhome/repo/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import plugin_eTVJQYlCmx from "/opt/buildhome/repo/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "/opt/buildhome/repo/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import sentry_3AyO8nEfhE from "/opt/buildhome/repo/plugins/sentry.ts";
import VueClickOutside_2I5l34vyfl from "/opt/buildhome/repo/plugins/VueClickOutside.ts";
import apolloError_client_hD6ZvBoHYn from "/opt/buildhome/repo/plugins/apolloError.client.js";
import i18n_VfGcjrvSkj from "/opt/buildhome/repo/plugins/i18n.ts";
import veevalidate_components_HZdjzYOWQC from "/opt/buildhome/repo/plugins/veevalidate-components.ts";
import veevalidate_rules_Xc2IpxFdQq from "/opt/buildhome/repo/plugins/veevalidate-rules.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tLmAP220nC,
  plugin_eTVJQYlCmx,
  plugin_t2GMTTFnMT,
  chunk_reload_client_UciE0i6zes,
  sentry_3AyO8nEfhE,
  VueClickOutside_2I5l34vyfl,
  apolloError_client_hD6ZvBoHYn,
  i18n_VfGcjrvSkj,
  veevalidate_components_HZdjzYOWQC,
  veevalidate_rules_Xc2IpxFdQq
]